:root {
	--primary-color: rgb(0, 123, 255);

	--popoverRed: rgba(255, 0, 0, 0.6);
	--popoverBlue: rgba(0, 123, 255, 0.6);
	--popoverYellow: rgba(255, 255, 0, 0.6);
	--popoverGreen: rgba(70, 255, 0, 0.6);

	--boxWidth: 244px;
}

.restrictedTitle {
	background-image: repeating-linear-gradient(45deg, 
  			rgba(200,200,200,0.8), rgba(200,200,200,0.8) 10px, 
  			rgba(200,200,200,0.8), rgba(200,200,200,0.8) 20px
        );
}

.linkLike{
	color: #0d6efd;
}
.linkLike:hover{
	cursor: pointer;
	color: #0044aa;
}

.pdfmodal-90w{
	max-width: 90vw !important;
	min-width: 500px;
	min-height: 90vh;
	max-height: 90vh;
	display: flex;
	flex-flow: row nowrap;
	justify-content: center;
}

.interfaceTitle {
	text-align: center;
	color: var(--primary-color);
	font-size: 150%;
}

.profilebox{
	background-color: rgb(233,236,239);
	width: 50vw;
	min-height: 25vh;
	padding: 2vw;
}
.profileForm{
	background-color: rgb(233,236,239);
	width: 50%;
}
.chScroll {
	overflow-y: auto;
	max-height: 80vh;
}
.titleContainer {
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
	align-items: center;
	width: clamp(500px, 80vw, 1000px);
}

.titleCard {
	font-size: larger;
	margin: 10px;
	width: 450px;
	border-radius: 10px 10px 5px 5px;
	background-color: white;
	color: black;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.titleCard:hover {
	color: var(--primary-color);
	text-decoration:none;
	box-shadow: none;
}

.innerCard {
	padding: 10px;
	white-space: initial;
	flex: 1 0 auto;
}

.title {
	color: black;
}
.title:hover{
	color: var(--primary-color);
	text-decoration:none;
}

.sideHeader {
	color: white;
	padding: 1vh;
	text-align: center;
	background-color: rgb(108, 117, 125);
}

.sideContent {
	padding: 2vh;
}

.card {
	width: 45vw;
	border: none !important;
	background-color: transparent;
}

.widthVW{
	width: 70vw;
	min-width: 500px;
}
.pushUnder{
	position: relative;
	z-index: 10;
}

.contextArea{
	max-height: 150px;
	overflow-y: auto;
}

.OneQuestion{
	height: 100vh;
	/* border: 2px solid red; */
	justify-self: stretch;
	justify-items: center;

	display: grid;
	grid-template-columns: 1fr 5fr 2fr;
	grid-template-rows: auto auto 30%;
	grid-template-areas: 
		". Question RightSidebar"
		". Buttons ."
		". . .";
}

.oqQuestionPart{
	grid-area: Question;
	width: 100%;
	min-width: 700px;
	display: flex;
	flex-flow: column wrap;
	justify-content: center;
	align-items: center;
	/* border: thin black dashed; */
}
.oqBtns{
	grid-area: Buttons;
}
.prevNxtBtns{
	width: 100%;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: flex-start;
	/* border: thick red dotted; */
}
.leftBtn{
	height: 2.2em;
	justify-self: flex-start;
}
.rightBtn{
	height: 2.2em;
	margin-left: auto;
}


.oqRightArea{
	grid-area: RightSidebar;
	display: flex;
	align-items: center;
	justify-content: center;
	min-width: 200px;
}
.rightGrid{
	/* max-height: 35vh; */
	/* min-height: 70%; */
	width: 95%;
	overflow-y: auto;
	overflow-x: hidden;
	background-color: transparent;
	color: black;
	/* border: thick pink dashed; */
}

.QnA{
	position: relative;
	padding: 10px;
	background-color: rgb(233, 236, 239);
	min-height: max(25vh, 200px);
	/* min-height: 100%; */
}

.Qpart{
	background-color: black;
	color: white;
}

.partInst {
	padding: 1vh;
	background-color: rgb(215, 220, 224);
}
.dots{
	margin-left: 2px;
	color: var(--primary-color);
}
.more {
	display: none;
}
.triggerText {
	cursor: pointer;
	margin-left: 10px;
	color: var(--primary-color);
	font-weight: bold;
}

.divider {
	border-bottom: 2px solid rgb(108,117,125);
	width: 70%;
}

.FlexBox {
	display: flex;
	padding-top: 0.5em;
	/* border: solid blue; */
}
.ansContainer{
	width: 100%;
	padding-left: 0.25vw;
	
	display: flex;
	flex-flow: column nowrap;
}
.AnsForm{
	display: grid;
	width: 100%;
	padding-left: 0.25vw;
	/* border: dashed red; */
}
.KeepRight{
	display: flex;
	justify-content: flex-end;
	margin-top: 10px;
}

.Title {
	display: flex;
	justify-content: center;
	transform: scale(1.2);
	margin-bottom: -3px;
}

Popover.Title {
	text-align: center;
}
	
.blueText {
	color: var(--primary-color);
}
.marginLeftAuto{
	margin-left: auto;
}

.analysis{
	font-size: 20px;
	margin-left: 8px;
	margin-bottom: 10px;
	white-space: pre-wrap;
}

.Home{
	align-self: center;
	margin-top: -20vh;
}

black{
	color: white;
	background-color: black;
}
yellow{
	color: black;
	background-color: yellow;
}
blue{
	color: black;
	background-color:  var(--primary-color);
}
red{
	color: black;
	background-color: red;
}
rtext{
	color:red;
}

a:link, a:visited, a:hover {
	/* color: black; */
	text-decoration: none !important;
	cursor: pointer;
}
  
a:link:active, a:visited:active {
	color: black;
}

/* Identificaiton Interface */
.spChar{
	color: var(--primary-color);
	font-size: 110%;
}
.colHeader{
	font-size: 110%;
	margin-bottom: 5px;
}
.interfaceCol {
	border: 1px lightgray solid;
	border-radius: 1em;

	margin: 5px;
	padding: 5px;
}

/* Syntax */
.inputGroup {
	display: flex;
	flex-flow: column nowrap;

	min-width: 100%;

	align-items: center;
	margin-top: 15px;
}

.praja32px {
	font-family: 'Praja';
	font-size: 32px;
	margin-left: 10px;
}

/* DRILLS */
.drillTable{
	width: 100%;
	display: flex;
	flex-flow: column nowrap;

	margin: 0px 5px;
}
.drillRow{
	width: 100%;
	margin-bottom: 10px;
	display: flex;
	flex-flow: row nowrap;
	/* align-items: center; */
}

.drillNum{
	min-width: 3%;
	max-width: 3%;
	margin-top: 5px;
	text-align: center;
}
.drillTitle{
	min-width: 10%;
	max-width: 10%;
	margin: 0px 10px 0px 5px;
	text-align: left;

	font-size: 28px;
	font-family: 'Praja';
}
.drillAnaBoxes{
	min-width: 70%;
	max-width: 70%;
	display: flex;
	flex-flow: row wrap;
}
.inputBox{
	min-width: 100%;
	max-width: 100%;
}
.inputBoxError {
	min-width: 25px;
	max-width: 25px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgb(233, 236, 239);
}
.inputBoxError > img {
	cursor: pointer;
}


.drillAnsBoxes{
	display: flex;
	flex-flow: row wrap;
}

.drillScroll{
	min-height: 200px;
	max-height: 45vh;
	overflow-y: auto;
	overflow-x: hidden;
}

/* Syntax Tree display*/
/* style={{maxWidth:'80vw', maxHeight:'80vh',
	display: 'flex', flexFlow: 'row nowrap',
	justifyContent: 'center', alignItems: 'center',
	
	position: 'absolute',
	top: '50%', left: '50%',
	transform: 'translate(-50%, -50%)'
}} */
.treeDialog{
	max-width: 80vw;
	max-height: 80vh;
	display: flex;
	flex-flow: row nowrap;
	justify-content: center;
	align-items: center;
}
.treeContent{
	max-width: fit-content;
	max-height: fit-content;
	margin: 20px;
	transform: scale(1.5);
}
.treeContent > img {
	object-fit: contain;
}